<template>
	<div id="office-wrap">
		<Header2022 :navbg='true'></Header2022>
		<div class="banner">
			<div class="">
				<div class="title">小院共享办公</div>
				<div class="subtitle">虚拟办公服务+基于社区图书馆的共享办公空间</div>
			</div>
			<div class="content">
				<div class="virtue">
					<div class="name">线上线下，全链打通</div>
					<div class="intr">为创业者提供一站式创业服务，包含虚拟办公服务，即通过小院生活门店注册公司，并实现专业行政与接电服务；同时线下所有小院社区图书馆均可作为创业者办公、会议场所，灵活、便捷、高效且大幅降低创业成本。</div>
				</div>
				<div class="virtue">
					<div class="name">多地通用，随定随用</div>
					<div class="intr">为创业者提供最便携的共享办公服务，小院共享办公旗下所有门店支持全店通定服务，即创业者可根据自己需求选择就近共享办公空间，随定随用，解决临时办公以及会议需求。</div>
				</div>
				<div class="virtue">
					<div class="name">基于社区图书馆的全方位服务</div>
					<div class="intr">小院共享办公服务基于小院图书馆门店，门店内除全套办公设施设备配备之外，提供图书免费借阅、精品咖啡、健康轻食以及多种多样阅读文化沙龙活动，告别传统共享办公固定空间与模式，为创业者提供多种增值服务。</div>
				</div>
			</div>
		</div>
		<div class="platform">
			<div class="headtitle">一站式创业平台</div>
			<div class="headintr">
				虚拟办公室（Virtual Office）是起源于欧美地区的一种办公室租用形式，其按需提供办公地址挂靠、行政文书处理、代接接电和商务接待等服务，租户可拥有黄金地段高级写字楼的商务地址，而不用在现场办公，方便树立租户的商业形象，并无需承担租金。创富港作为最早把商务中心模式引入中国地区的连锁企业，以自主研发并获国家软件认证的BITS商务智能电话系统为运作硬件基础，向租户们提供最完善的虚拟办公室服务。
			</div>
			<div class="service-list">
				<div class="service-item" v-for="(serviceItem,serviceIndex) in serviceList" :key="serviceIndex">
					<img :src="serviceItem.image" >
					<div class="name">{{serviceItem.name}}</div>
					<div class="address">{{serviceItem.address}}</div>
					<div class="introduce">{{serviceItem.intr}}</div>
				</div>
			</div>
		</div>
		<div class="spacetype">
			<div class="headtitle">空间类型</div>
			<div class="headintr">
				从移动工位到会议室，小院生活打造可提高工作效率、创新和协作的办公环境。
			</div>
			<div class="type-list">
				<div class="type-item" v-for="(typeItem,typeIndex) in typeList" :key="typeIndex">
					<div class="type-left">
						<div class="name">{{typeItem.name}}</div>
						<div class="intr">{{typeItem.intr}}</div>
						<el-popover placement="top" width="300" trigger="hover">
							<img src="../../assets/images/xiaochengxu-lifehall.png">
							<el-button slot="reference">立即咨询></el-button>
						</el-popover>
					</div>
					<img :src="typeItem.image" class="image">
				</div>
			</div>
		</div>
	    <div class="hotcity">
			<div class="headtitle">探索热门城市</div>
			<div class="headintr">
				西安、渭南，五大办公地点中，随时随地选择您的理想办公空间
			</div>
			<div class="stores">
				<stores :img="img" :imgArr="imgArr"></stores>
			</div>
		</div>
		<div class="facilities">
			<div class="facilities-wrap">
				<div class="list">
					<div class="headtitle">服务设施</div>
					<div class="intr">从精品咖啡到新鲜水果饮用水，从企业级打印机到健身空间， 我们的服务设施考虑您的多种办公需求</div>
					<div class="service-type">
						<div class="service-list">
							<div>·星巴克咖啡服务</div>
							<div>·水吧简餐</div>
							<div>·图书借阅</div>
							<div>·WIFI</div>
						</div>
						<div class="service-list">
							<div>·休息区</div>
							<div>·活动区</div>
							<div>·会议区</div>
							<div>·饮用水</div>
						</div>
						<div class="service-list">
							<div>·打印/复印机</div>
							<div>·储物柜</div>
							<div>·话筒音响</div>
							<div>·投影设备</div>
						</div>
						<div class="service-list">
							<div>·停车场</div>
							<div>·健身器材/健身房</div>
						</div>
					</div>
				</div>
				<div class="contact">
					<div class="invite">正在寻找一个灵活高端的办公空间？ 预约参观，我们将尽快与您确认。</div>
					<el-popover placement="top" width="300" trigger="hover">
						<img src="../../assets/images/xiaochengxu-lifehall.png">
						<el-button slot="reference" class="reserveBtn">立即预约参观</el-button>
					</el-popover>
					<!-- <div class="btn">立即预约参观</div> -->
				</div>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import stores from '@/components/store-list.vue'
	import Footer2022 from '@/components/footer2022.vue'
	export default {
		components: {
			Header2022,
			Footer2022,	
			stores
		},
		data() {
			return {
				serviceList:[
					{
						image:require("../../assets/images/life/office1.png"),
						name:'企业托管服务',
						intr:'无需租用办公室，却可拥有专业企业形象。服务内容包含公司注册、变更等，轻松提升企业形象'
					},
					{
						image:require("../../assets/images/life/office2.png"),
						name:'收件服务',
						intr:'无需支付工资，却拥有专属行政人员。专业行政人员提供信件、包裹收取以及行政服务'
					},
					{
						image:require("../../assets/images/life/office3.png"),
						name:'秘书接电服务',
						intr:'无需支付工资，可拥有专属秘书接电服务，即使没有办公室，依然拥有专属秘书以公司名义接听电话'
					}
				],
				typeList:[
					{
						image:require("../../assets/images/life/office4.png"),
						name:'随心座',
						intr:'针对个人客户、商旅人士推出的灵活办公解决方案。到店出示会员，即可随订随用，助您轻松工作，成就更多。',
						
					},
					{
						image:require("../../assets/images/life/office5.png"),
						name:'移动工位',
						intr:'一张桌子，一个专属存储柜，一个移动矮柜，开启自己的工作生活，灵活空间选择。',
					},
					{
						image:require("../../assets/images/life/office6.png"),
						name:'会议室预订',
						intr:'每个空间均有大小不等会议室为您提供，提前预约可直接使用，进行您的会议议程、培训讲座、合作商务会议举办等。',
					},
				],
				img: [{
						image: require("../../assets/images/life/store1.png"),
						name: '泾渭五路社区馆',
				
					},
					{
						image: require("../../assets/images/life/store3.png"),
						name: 'YUARD大雁塔帛薇店',
					},
					{
						image: require("../../assets/images/life/store4.png"),
						name: '渭南 经开·未来城图书馆',
					},
					{
						image: require("../../assets/images/life/store5.png"),
						name: '奥韵华府社区馆',
					},
				],
				imgArr: [{
						image: require("../../assets/images/life/store1.png"),
						name: '泾渭五路社区馆',
					},
					{
						image: require("../../assets/images/life/store3.png"),
						name: 'YUARD大雁塔帛薇店',
					},
					{
						image: require("../../assets/images/life/store4.png"),
						name: '渭南 经开·未来城图书馆',
					},
					{
						image: require("../../assets/images/life/store5.png"),
						name: '奥韵华府社区馆',
					},
				],
			}
		},

		methods: {
			cutTap(e){
				
			}
		}
	}
</script>

<style lang="scss">
	#office-wrap{
		.el-button {
			border: 0;
			color: #000000;
			padding-left: 0;
		}
		.reserveBtn{
			font-size: 12px;
			text-align: center;
			cursor: pointer;
			width: 256px;
			background-color: rgba(0, 0, 0, 0);
			height: 40px;
			border: 2px solid #000000;	
			border-radius: 20px;
		}
		.banner {
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			background: url('../../assets/images/life/officebg.png') no-repeat;
			background-size: cover;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			.title{
				font-size: 42px;
				font-weight: bold;
				line-height: 42px;
				text-align: center;
				padding-top: 40px;
			}
			.subtitle{
				font-size: 30px;
				line-height: 30px;
				margin-top:20px;
			}
			.content{
				display: flex;
				justify-content:space-around;
				align-items: center;
				.virtue{
					width: 390px;
					margin: 0 40px;
					height: 100%;
					.name{
						font-size: 30px;
						font-weight: bold;
						line-height: 30px;
						margin-bottom: 24px;
						text-align: center;
					}
					.intr{
						font-size: 16px;
						line-height: 32px;
					}
				}
				@media only screen and (max-width:1440px){
					.virtue{
						width: 340px;
						.name{
							font-size: 26px;
							line-height: 26px;
						}
						.intr{
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
				@media only screen and (max-width:1280px){
					.virtue{
						width: 320px;
						.name{
							font-size: 24px;
							line-height: 24px;
						}
						.intr{
							font-size: 14px;
							line-height: 22px;
						}
					}
				}
			}
		}
		.headtitle{
			font-size: 40px;
			line-height: 40px;
			text-align: center;
			font-weight: bold;
			color: #000000;
		}
		.platform {
			color: #000000;
			font-weight: bold;
			padding: 99px 0px 112px;
			box-sizing: border-box;
			background: #F6F8FA;
			.headintr{
				font-size: 16px;
				font-weight: 400;
				line-height: 30px;
				margin: 50px auto 82px;
				text-align: center;
				width: 1293px;
			}
			@media only screen and (max-width:1366px){
				.headintr{
					width: 1114px;
				}
			}
			.service-list{
				display: flex;
				justify-content: space-around;
				.service-item{
					width: 23.4%;
					text-align: center;
					img{
						width:100%;
					}
					.name{
						font-size: 30px;
						line-height: 30px;
						margin: 40px 0 17px;
					}
					.introduce{
						padding: 0 20px;
						font-size: 16px;
						font-weight: 400;
						line-height: 36px;
					}
				}
			}
		}
		.spacetype{
			color: #000000;
			padding: 88px 0px 92px;
			.headintr{
				font-size: 24px;
				line-height: 24px;
				margin: 29px 0 80px;
				text-align: center;
			}
			.type-list{
				width: 75%;
				margin: 0 auto;
				.type-item{
					display: flex;
					justify-content: center;
					box-shadow: 0px 2px 13px 0px rgba(31,31,32,0.3000);
					border-radius: 15px;
					margin-bottom: 30px;
					.image{
						width:50%;
					}
					.type-left{
						padding: 10% 10.4% 7.4% 6.25%;
						.name{
							font-size: 30px;
							font-weight: bold;
							line-height: 30px;
							margin-bottom: 36px;
						}
						.intr{
							font-size: 16px;
							line-height: 30px;
							margin-bottom: 11.11%;
							font-weight: bold;
						}
						.more{
							font-size: 16px;
							line-height: 16px;
						}
					}
				}
			}
		}
		.facilities{
			padding-bottom:66px;
			color: #000000;
			background: #F6F8FA;
			.headtitle{
				text-align: left;
			}
			.facilities-wrap{
				display: flex;
				justify-content: space-around;
				.list{
					.intr{
						font-size: 24px;
						line-height: 40px;
						margin-top: 33px;
						width: 630px;
					}
					.service-type{
						display: flex;
						justify-content: space-between;
						margin-top: 22px;
						.service-list{
							margin-right: 60px;
							font-size: 16px;
							line-height: 30px;
							font-weight: bold;
						}
						
					}
				}
				.contact{
					.invite{
						font-size: 30px;
						font-weight: bold;
						line-height: 40px;
						margin: 76px 0 97px;
						width: 480px;
					}
					.btn{
						width: 256px;
						height: 40px;
						border: 2px solid #000000;
						border-radius: 20px;
						font-size: 12px;
						line-height: 40px;
						text-align: center;
					}
				}
			}
		}
		.hotcity{
			padding-top: 66px;
			color: #000000;
			background: #F6F8FA;
			.headintr{
				font-size: 24px;
				line-height: 24px;
				margin: 29px 0 70px;
				text-align: center;
			}
			.stores{
				padding-bottom: 87px;
			}
		}
	}
	
</style>
